
































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import UserRolesAutocomplete from '@/components/Autocomplete/UserRolesAutocomplete.vue';
import DistChannelAutocomplete from '@/components/Autocomplete/DistChannelAutocomplete.vue';
import CustomerAutocomplete from '@/components/Autocomplete/CustomerAutocomplete.vue';
import { UserService } from '@/services';
import Logger from '@/tools/Logger';
import { alert } from '@/plugins/genify/generators/alert';
import { LazyPromise } from '@/lib/LazyPromise';

type UsersCreateData = {
  name: string;
  email: string;
  roles: string[];
  dist_channels: string[];
  assigned_sites: any[];
};

type UsersCreateProps = {
  confirmText: string;
  cancelText: string;
};

type UsersCreateResponse = {
  data: UsersCreateData;
};

@Component({
  name: 'UsersCreateDialog',
  components: {
    UserRolesAutocomplete,
    DistChannelAutocomplete,
    CustomerAutocomplete,
  },
})
export class UsersCreateDialog extends Vue {
  /**
   * Promise for resolving a value through asynchronously
   */
  @Prop({ required: true, default: undefined })
  protected readonly promise!: LazyPromise<any>;

  /**
   * Confirmation text for dialog-form's affirmative action
   */
  @Prop({ required: false, default: 'Send Invitation' })
  protected readonly confirmText!: string;

  /**
   * Cancellation text for dialog-form's negative action
   */
  @Prop({ required: false, default: 'Cancel' })
  protected readonly cancelText!: string;

  protected dialog = true;

  protected rules = {
    required: (value: string) => !!value || 'Required.',
    email: (value: string): true | 'Invalid e-mail' => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || 'Invalid e-mail';
    },
  };

  protected data: UsersCreateData = {
    name: '',
    email: '',
    roles: [],
    dist_channels: [],
    assigned_sites: [],
  };

  protected inviteMessage = '';

  protected valid = true;

  private readonly logger: Logger = new Logger({ context: 'UsersCreateDialog' });

  private readonly userService: UserService = UserService.getInstance();

  /**
   * Return a helper function for displaying an UploaderDialog modal.
   */
  public static attach<P = UsersCreateProps, R = UsersCreateResponse>(props?: P): () => Promise<R> {
    return async function(this: Vue) {
      const lazyPromise = new LazyPromise();
      const instance = new UsersCreateDialog({
        parent: this,
        propsData: {
          ...props,
          promise: lazyPromise,
          confirmText: 'Invite',
          cancelText: 'Cancel',
        },
      });
      instance.$mount();
      return await lazyPromise;
    };
  }

  /**
   * Clear dist_channel and assigned_sites fields to prevent lingering data
   */
  @Watch('data.roles')
  protected onDataChange(roles: string[]) {
    if (!roles.includes('contract_advisor')) {
      this.data.dist_channels = [];
    }
    if (!roles.includes('site_user')) {
      this.data.assigned_sites = [];
    }
  }

  protected confirm() {
    this.dialog = false;
    this.promise.resolve({
      data: this.data,
    });
    this.$destroy();
  }

  protected cancel() {
    this.dialog = false;
    this.reset();
    this.promise.reject();
    this.$destroy();
  }

  protected async invite() {
    if (!((this.$refs.form as any).validate())) return;

    try {
      await this.userService.api.sendInvitation({
        authentication_token: this.userService.getActiveToken(),
        order_form_user: {
          ...this.data,
          assigned_sites: this.data.assigned_sites.map(o => o.value),
        },
        invitation_message: this.inviteMessage,
      });
      this.$genify.notify(`An invitation email has been sent to ${this.data.email}!`, 'success');
      this.confirm();
    } catch (error) {
      const showAlert = alert.apply(this, [{ el: 'users-create-dialog__alerts' }]).bind(this);
      if (error.isAxiosError &&
          error.response.status === 401 &&
          error.response.data.email[0] === 'has already been taken') {
        showAlert(
          `Account already exists for ${this.data.email}.`,
          'error',
        );
      } else {
        showAlert(
          'There was an error sending the invitation. Please try again later.',
          'error',
        );
      }
      this.logger.error(error);
    }
  }

  private resetData() {
    this.data.email = '';
    this.data.name = '';
    this.data.roles = [];
    this.data.dist_channels = [];
    this.data.assigned_sites = [];
    this.inviteMessage = '';
  }

  private reset() {
    (this.$refs.usersCreateDialogAlerts as any).innerHTML = '';
    (this.$refs.form as any).reset();
    this.resetData();
  }
}

export default UsersCreateDialog;
