































































import { Vue, Component, PropSync } from 'vue-property-decorator';
import DistChannelAutocomplete from '@/components/Autocomplete/DistChannelAutocomplete.vue';

@Component({
  name: 'UsersListAddDistChannel',
  components: {
    DistChannelAutocomplete,
  },
})
export class UsersListAddDistChannel extends Vue {
  @PropSync('show', { required: true })
  public syncedShow!: boolean;

  public selected: any = null;

  protected onClickCancel() {
    this.syncedShow = false;
  }

  protected onClickAdd() {
    this.$emit('add', this.selected);
    this.syncedShow = false;
  }
}

export default UsersListAddDistChannel;
