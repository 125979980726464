var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-list',{attrs:{"height":"100%","dense":"","outlined":""}},[_c('v-subheader',{staticClass:"pl-4"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-account-cog ")]),_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("User Roles")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.showAddRole,"color":"success","text":"","fab":"","small":""},on:{"click":function($event){_vm.showAddRole = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Add role")])])],1),_c('v-slide-y-transition',[(_vm.showAddRole)?_c('users-list-add-role',{attrs:{"show":_vm.showAddRole},on:{"update:show":function($event){_vm.showAddRole=$event},"add":_vm.onClickAddRole}}):_vm._e()],1),(_vm.user.roles.length)?_c('v-divider'):_vm._e(),_vm._l((_vm.user.roles),function(role,i){return [_c('v-hover',{key:((_vm.user.id) + "_dist_channel_" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{key:((_vm.user.id) + "_dist_channel_" + i),class:(hover) ? 'px-2 grey lighten-4' : 'px-2'},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"grey lighten-4"}},[_vm._v(" "+_vm._s(i + 1)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, _vm.titleCase(role)))}})])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.onClickRemoveRole(role)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove role")])])],1)],1)]}}],null,true)})]})],2)],1),(_vm.user.roles.includes('contract_advisor'))?_c('v-col',[_c('v-list',{attrs:{"height":"100%","dense":"","outlined":""}},[_c('v-subheader',{staticClass:"pl-4"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-warehouse ")]),_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Distribution Channels")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.showAddDistChannel,"color":"success","text":"","fab":"","small":""},on:{"click":function($event){_vm.showAddDistChannel = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,251337820)},[_c('span',[_vm._v("Add distribution channel")])])],1),_c('v-slide-y-transition',[(_vm.showAddDistChannel)?_c('users-list-add-dist-channel',{attrs:{"show":_vm.showAddDistChannel},on:{"update:show":function($event){_vm.showAddDistChannel=$event},"add":_vm.onClickAddDistChannel}}):_vm._e()],1),(_vm.user.dist_channels.length)?_c('v-divider'):_vm._e(),_vm._l((_vm.user.dist_channels),function(dist_channel,i){return [_c('v-hover',{key:((_vm.user.id) + "_dist_channel_" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{key:((_vm.user.id) + "_dist_channel_" + i),class:(hover) ? 'px-2 grey lighten-4' : 'px-2'},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"grey lighten-4"}},[_vm._v(" "+_vm._s(i + 1)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, dist_channel))}})])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.onClickRemoveDistChannel(dist_channel)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove distribution channel")])])],1)],1)]}}],null,true)})]})],2)],1):_vm._e(),(_vm.user.roles.includes('site_user'))?_c('v-col',[_c('v-list',{attrs:{"height":"100%","dense":"","outlined":""}},[_c('v-subheader',{staticClass:"pl-4"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(" mdi-map-marker-multiple ")]),_c('span',{staticClass:"text-h6 font-weight-bold"},[_vm._v("Assigned Sites")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.showAddSiteRequest,"color":"success","text":"","fab":"","small":""},on:{"click":function($event){_vm.showAddSiteRequest = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3973071100)},[_c('span',[_vm._v("Add site")])])],1),_c('v-slide-y-transition',[(_vm.showAddSiteRequest)?_c('users-list-add-site-request',{attrs:{"show":_vm.showAddSiteRequest},on:{"update:show":function($event){_vm.showAddSiteRequest=$event},"add":_vm.onClickAddSite}}):_vm._e()],1),(_vm.user.assigned_sites.length)?_c('v-divider'):_vm._e(),_vm._l((_vm.user.assigned_sites),function(site,i){return [_c('v-hover',{key:((_vm.user.id) + "_site_" + i),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{key:((_vm.user.id) + "_site_" + i),class:(hover) ? 'px-2 grey lighten-4' : 'px-2'},[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"grey lighten-4"}},[_vm._v(" "+_vm._s(i + 1)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlightSearchText(_vm.search, site.name))}})])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.onClickRemoveSite(site)}}},on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove site")])])],1)],1)]}}],null,true)})]})],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }