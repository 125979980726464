

























































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import SearchFieldTooltip from '@/components/Tooltip/SearchFieldTooltip.vue';

type UsersListControlsAction = {
  label: string;
  icon: string;
  value: string;
};

@Component({
  name: 'UsersListControls',
  components: {
    SearchFieldTooltip,
  },
})
export default class UsersListControls extends Vue {
  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @PropSync('search')
  protected searchText!: string;

  @PropSync('show-locked')
  protected showAccessLocked!: boolean;

  @Prop({ required: false, default: () => [] })
  protected readonly actions!: UsersListControlsAction[];
}
