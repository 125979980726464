






























































import { Vue, Component, PropSync } from 'vue-property-decorator';
import CustomerAutocomplete from '@/components/Autocomplete/CustomerAutocomplete.vue';

@Component({
  name: 'UsersListAddSiteRequest',
  components: {
    CustomerAutocomplete,
  },
})
export class UsersListAddSiteRequest extends Vue {
  @PropSync('show', { required: true })
  protected syncedShow!: boolean;

  private selected: any = null;

  protected onClickCancel() {
    this.syncedShow = false;
  }

  protected onClickAdd() {
    this.$emit('add', {
      id: this.selected.value,
      name: this.selected.text,
    });
    this.syncedShow = false;
  }
}

export default UsersListAddSiteRequest;
