























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Loading } from '@/lib/types';
import { User } from '@/models/internal';
import { UserService } from '@/services';
import { DataTableHeader } from 'vuetify';
import { ITableView } from '@/lib/interfaces';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'DirectorsOnCallList',
})
export default class DirectorsOnCallList extends Vue implements ITableView<User> {
  public data: User[] = [];

  public selected: User[] = [];

  public headers: DataTableHeader[] = [
    { text: 'Name', value: 'name', width: 220 },
    { text: 'Email', value: 'email', width: 400 },
    { text: 'On Call', value: 'is_on_call', width: 140 },
  ];

  public search = '';

  private readonly logger: Logger = new Logger({ context: 'AdminsOnCallList' });

  private readonly userService: UserService = UserService.getInstance();

  private readonly loading: Loading = {
    table: false,
  };

  public created() {
    this.init();
  }

  public async fetchData() {
    const user = this.userService.getActive();
    if (!user) throw Error('Unable to find active User');
    return this.userService.api.find({
      authentication_token: user.authentication_token,
      with_role: 'project_admin',
    });
  }

  public async init() {
    this.loading.table = true;
    try {
      const users = await this.fetchData();
      this.data = users;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.table = false;
    }
  }

  /**
   * Update user's on call status
   */
  protected async isOnCallChanged(user: User) {
    const activeUser = this.userService.getActive();
    if (!activeUser) throw Error('Unable to find active User');

    try {
      const updatedUser = await this.userService.api.onCall({
        id: user.id,
        authentication_token: activeUser.authentication_token,
        is_on_call: user.is_on_call,
      });
      if (updatedUser.is_on_call) {
        this.$genify.notify(`${updatedUser.name} is on call!`, 'success');
      } else {
        this.$genify.notify(`${updatedUser.name} is no longer on call.`, 'success');
      }
    } catch (error) {
      user.is_on_call = false;
      this.logger.error(error);
      this.$genify.alert(`There was an error updated ${user.name}'s "On Call" status.`, 'error');
    }
  }
}
