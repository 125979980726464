























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Logger } from '@/tools/Logger';
import Utility from '@/tools/Utility';
import type { VAutoCompleteItem } from '@/lib/types/Vuetify.type';

@Component({
  name: 'UserRolesAutocomplete',
})
export default class UserRolesAutocomplete extends Vue {
  @Prop({ required: false, default: undefined })
  protected name?: string;

  @Prop({ required: false, default: false })
  protected dense!: boolean;

  @Prop({ required: false, default: false })
  protected outlined!: boolean;

  @Prop({ required: false, default: false })
  protected chips!: boolean;

  @Prop({ required: true })
  protected label!: string;

  @Prop({ required: false, default: () => [] })
  protected value!: string[];

  /**
   * Attributes to be bound to component in template
   */
  @Prop({ required: false, default: () => ({}) })
  protected options!: Record<string, any>;

  @Prop({ required: false, default: false })
  private multiple!: boolean;

  protected loading = false;

  protected data: VAutoCompleteItem[] = [];

  private readonly logger: Logger = new Logger({ context: 'UserRolesAutocomplete' });

  private readonly titleCase = Utility.titleCase;

  private selected: string | string[] = '';

  @Watch('value')
  protected watchValue(value: string[]) {
    this.selected = value;
  }

  public created() {
    if (this.multiple) this.selected = [];
    this.init();
  }

  private async init() {
    this.loading = true;
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
      return [];
    } finally {
      this.loading = false;
    }
  }

  /**
   * Fetches roles from config
   */
  private async fetchData() {
    return (await Utility.fetchConfig()).defaults.roles.map((role) => ({
      text: this.titleCase(role),
      value: role,
    }));
  }

  /**
   * Change event listener
   */
  private change() {
    return this.emitChange();
  }

  /**
   * Emit event for changed data
   */
  private emitChange() {
    this.$emit('input', this.selected);
  }
}
