


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CustomerService, UserService } from '../../services';
import { Logger } from '@/tools/Logger';
import { debounce } from 'lodash';
import type { VAutoCompleteItem } from '@/lib/types/Vuetify.type';

@Component({
  name: 'CustomerAutocomplete',
})
export class CustomerAutocomplete extends Vue {
  @Prop({ required: false, default: undefined })
  protected name?: string;

  @Prop({ required: false, default: false })
  protected dense!: boolean;

  @Prop({ required: false, default: false })
  protected outlined!: boolean;

  @Prop({ required: false, default: false })
  protected chips!: boolean;

  @Prop({ required: true })
  protected label!: string;

  /**
   * Attributes to be bound to component in template
   */
  @Prop({ required: false, default: () => ({}) })
  protected options!: Record<string, any>;

  @Prop({ required: false, default: () => [] })
  protected value!: string[];

  @Prop({ required: false, default: true })
  private debouncedChange!: boolean;

  @Prop({ required: false, default: false })
  private multiple!: boolean;

  protected hideNoData = false;

  protected loading = false;

  private selected: VAutoCompleteItem | VAutoCompleteItem[] = {
    text: '',
    value: '',
  };

  private search = null;

  private data: VAutoCompleteItem[] = [];

  private debouncedEmitChange = debounce(this.emitChange, 500);

  private debouncedInit = debounce(this.init, 500);

  private readonly logger: Logger = new Logger({ context: 'DistChannelAutocomplete' });

  private readonly customerService = CustomerService.getInstance();

  private readonly userService = UserService.getInstance();

  @Watch('value')
  protected watchValue(value: VAutoCompleteItem) {
    this.selected = value;
  }

  @Watch('search')
  protected watchSearch(val: string) {
    if (!val) return; // Prevent field from reloading on losing focus
    if (this.multiple) {
      if ((this.selected as VAutoCompleteItem[]).filter((o: VAutoCompleteItem) => o.text === val).length > 0) return;
    } else {
      if ((this.selected as VAutoCompleteItem).text === val) return; // Prevent fetch on selection
    }
    this.data = [];
    this.loading = true;
    this.hideNoData = true;
    this.debouncedInit();
  }

  public created() {
    if (this.multiple) this.selected = [];
    this.init();
  }

  private async init() {
    this.loading = true;
    this.hideNoData = true;
    try {
      const { customers } = await this.fetchData();
      this.data = customers.map((customer: any) => ({
        text: customer.name,
        value: customer.destination_customer_id,
      }));

      // Inject selected items into data if they don't exist so they can be displayed in field
      if (Array.isArray(this.selected) && this.selected.length > 0) {
        this.selected.forEach((o: VAutoCompleteItem) => {
          const exists = this.data.find((p) => p.value === o.value);
          if (exists) return;
          this.data.unshift(o);
        });
      }
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
      this.hideNoData = false;
    }
  }

  /**
   * Fetch the distribution channels
   */
  private async fetchData() {
    return this.customerService.api.autocomplete({
      authentication_token: this.userService.getActiveToken(),
      name_containing: this.search,
    });
  }

  /**
   * Change event listener
   */
  private change() {
    return (this.debouncedChange) ? this.debouncedEmitChange(): this.emitChange();
  }

  /**
   * Emit event for changed data
   */
  private emitChange() {
    this.$emit('input', this.selected);
  }
}

export default CustomerAutocomplete;
