













import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserRole } from '@/models/functions/defineAbility';
import Utility from '@/tools/Utility';

@Component({
  name: 'UsersListRole',
})
export class UsersListRole extends Vue {
  @Prop({ required: true })
  protected readonly role!: UserRole;

  @Prop({ required: false })
  protected label?: string;

  protected getIcon(): string {
    switch (this.role) {
      case UserRole.SiteUser:
        return 'mdi-account';
      case UserRole.ContractAdvisor:
        return 'mdi-account-check';
      case UserRole.Helpdesk:
        return 'mdi-account-heart';
      case UserRole.ProfessionalPractice:
      case UserRole.SwabLead:
      case UserRole.OccHealthLead:
        return 'mdi-account-tie';
      case UserRole.ProjectAdmin:
        return 'mdi-account-cowboy-hat';
      case UserRole.Admin:
        return 'mdi-account-hard-hat';
      default:
        return 'mdi-account';
    }
  }

  protected getLabel() {
    return this.label ?? Utility.titleCase(this.role);
  }
}

export default UsersListRole;
