


























































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AssignedSite, User } from '@/models/internal';
import UsersListAddSiteRequest from './UsersListAddSiteRequest.vue';
import UsersListAddDistChannel from './UsersListAddDistChannel.vue';
import UsersListAddRole from './UsersListAddRole.vue';
import Utility from '@/tools/Utility';

@Component({
  name: 'UsersListDetails',
  components: {
    UsersListAddSiteRequest,
    UsersListAddDistChannel,
    UsersListAddRole,
  },
})
export default class UsersListDetails extends Vue {
  @Prop({ required: false, default: '' })
  protected readonly search!: string;

  @Prop({ required: true })
  private readonly user!: User;

  public showAddRole = false;

  public showAddDistChannel = false;

  public showAddSiteRequest = false;

  protected readonly titleCase = Utility.titleCase;

  protected highlightSearchText = Utility.highlightSearchText;

  protected onClickAddDistChannel(distChannel: string) {
    this.$emit('add-dist-channel', this.user, distChannel);
  }

  protected onClickRemoveDistChannel(distChannel: string) {
    this.$emit('remove-dist-channel', this.user, distChannel);
  }

  protected onClickAddSite(site: AssignedSite) {
    this.$emit('add-site', this.user, site);
  }

  protected onClickRemoveSite(site: AssignedSite) {
    this.$emit('remove-site', this.user, site);
  }

  protected onClickAddRole(role: string) {
    this.$emit('add-role', this.user, role);
  }

  protected onClickRemoveRole(role: string) {
    this.$emit('remove-role', this.user, role);
  }
}
