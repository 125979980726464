





























































import { Vue, Component, PropSync } from 'vue-property-decorator';
import UserRolesAutocomplete from '@/components/Autocomplete/UserRolesAutocomplete.vue';
import type { VAutoCompleteItem } from '@/lib/types/Vuetify.type';

@Component({
  name: 'UsersListAddRole',
  components: {
    UserRolesAutocomplete,
  },
})
export class UsersListAddRole extends Vue {
  @PropSync('show', { required: true })
  protected syncedShow!: boolean;

  protected selected: any = null;

  protected data: VAutoCompleteItem[] = [];

  protected onClickCancel() {
    this.syncedShow = false;
  }

  protected onClickAdd() {
    this.$emit('add', this.selected);
    this.syncedShow = false;
  }
}

export default UsersListAddRole;
